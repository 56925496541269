import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";

import {
  FaAward,
  FaBriefcase,
  FaCalendarAlt,
  FaCreativeCommonsBy,
  FaFile,
  FaUser,
  FaUsers,
  FaWpforms,
} from "react-icons/fa";
import { getProgramByFiscalYearIdByFYandProgramId } from "../../services/getProgramService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import auth from "../../services/authService";
import { checkReportingPeriod } from "../../services/reportingPeriodsService";
import userPrograms from "../../services/userPrograms";

const ProviderSidebar = ({
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  fiscalYear,
}) => {
  const [show, setShow] = useState(false);
  const [isMultiple, setIsMultiple] = useState("");
  const [data, setData] = useState([]);

  //const now = sessionStorage.setItem("DateTimeNow", moment().month('MMMM').format('YYYY-MM-D')+'T00:00:00');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = () => {
    setShow(false);
    window.location.reload(false);
  };

  function findArrayElementByEdit(array, Id) {
    return array[array.findIndex((x) => x.programId === Id)];
  }

  let sProgramId;
  async function fetchData() {
    const user = await auth.getCurrentUser();
    if (fiscalYear.value > 0) {
      const { data } = await userPrograms.getUserProgramsByIdAndFy(
        user.UserId,
        fiscalYear.value
      );

      setData(data);
    }

    setIsMultiple(data.length > 1 ? true : false);

    sProgramId = sessionStorage.getItem("ProgramId");

    if (isMultiple === true && sProgramId == null) setShow(true);

    if (isMultiple === false && sProgramId === null && data.length > 0) {
      sessionStorage.setItem("ProgramId", data[0].programId);
      sessionStorage.setItem(
        "ProgramByFiscalYearId",
        data[0].programByFiscalYearId
      );
      sessionStorage.setItem("UserId", user.UserId);
    }

    if (sProgramId && fiscalYear?.value) {
      try {
        const { data: programsbyId } =
          await getProgramByFiscalYearIdByFYandProgramId(
            fiscalYear.value,
            sProgramId
          );
        sessionStorage.setItem("ProgramByFiscalYearId", programsbyId.id);
      } catch (error) {
        alert("Sorry This An Error Getting Program By Fiscal Year...");
      }
    }

    try {
      const { data: checkReportingId } = await checkReportingPeriod();
      sessionStorage.setItem("ReportingPeriod", checkReportingId.id);
      sessionStorage.setItem("beginDate", checkReportingId.beginDate);
      sessionStorage.setItem("endDate", checkReportingId.endDate);
      sessionStorage.setItem(
        "beginDateDisplay",
        checkReportingId.displayBeginDate
      );
      sessionStorage.setItem("endDateDisplay", checkReportingId.displayEndDate);
      sessionStorage.setItem("periodId", checkReportingId.id);
      sessionStorage.setItem("UserId", user.UserId);
    } catch (error) {
      console.warn("Sorry No Reporting Period Found");
    }
  }

  useEffect(() => {
    fetchData();
  }, [isMultiple, fiscalYear, sProgramId]);

  return (
    <>
      <ProSidebar
        id="proSidebar"
        image={false}
        rtl={rtl}
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <div className="pssfLoggedintext text-center">
            <img
              className="ourLogo"
              src={require("../../../src/img/pssf-logo-solo.png")}
              alt="Logo"
            />
          </div>
          <div className="text-left text-gray text-center">Provider</div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            <MenuItem>
              <Link
                className="text-success"
                onClick={handleShow}
                to={window.location.pathname}
                role="button"
              >
                <div className="text-center fw-bold">Switch Program</div>
              </Link>
            </MenuItem>
            <MenuItem icon={<FaCalendarAlt />}>
              <Link to={`/provider/currentfy/home`} state={{ fiscalYear }}>
                Home
              </Link>
            </MenuItem>
            <MenuItem icon={<FaWpforms />}>
              <Link
                to={`/provider/editapprovedservices/`}
                state={{ fiscalYear }}
              >
                Services
              </Link>
            </MenuItem>
            <SubMenu title="Reports" icon={<FaFile />}>
              <MenuItem>
                <Link
                  to="/provider/programmaticreports"
                  rel="noopener noreferrer"
                >
                  Programmatic Report
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/provider/viewreports" rel="noopener noreferrer">
                  View Reports
                </Link>
              </MenuItem>
            </SubMenu>

            <SubMenu title="Cases" icon={<FaBriefcase />}>
              <MenuItem>
                <Link
                  to="/provider/currentfy/newcase"
                  rel="noopener noreferrer"
                >
                  New Cases
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/provider/currentfy/pendingcases"
                  rel="noopener noreferrer"
                >
                  Pending Cases
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/provider/currentfy/opencases"
                  rel="noopener noreferrer"
                  onClick={sessionStorage.removeItem("CaseId")}
                >
                  Open Cases
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/provider/archivedcases"
                  rel="noopener noreferrer"
                  onClick={sessionStorage.removeItem("CaseId")}
                >
                  Archived Cases
                </Link>
              </MenuItem>
            </SubMenu>

            <MenuItem icon={<FaUsers />}>
              <Link
                to="/provider/usermanagement"
                rel="noopener noreferrer"
                icon={<FaUsers />}
              >
                User Management
              </Link>
            </MenuItem>

            <MenuItem icon={<FaCreativeCommonsBy />}>
              <Link
                to="/provider/staffqualifications"
                rel="noopener noreferrer"
                icon={<FaUsers />}
              >
                Staff Qualifications
              </Link>
            </MenuItem>

            <MenuItem icon={<FaAward />}>
              <Link
                to="/provider/trainingResources"
                rel="noopener noreferrer"
                icon={<FaUsers />}
              >
                Training & Resources
              </Link>
            </MenuItem>

            {/* <SubMenu title="Cases" icon={<FaBriefcase />}>

            <MenuItem>
            <Link to="/provider/newexit" rel="noopener noreferrer">
                New Exit
            </Link>
            </MenuItem>
            <MenuItem>
            <Link to="/provider/opencases" rel="noopener noreferrer">
                Open/Pending Cases
            </Link>
            </MenuItem>
            <MenuItem>
            <Link to="/provider/archivedcases" rel="noopener noreferrer">
                Archived Cases
            </Link>
            </MenuItem>
        </SubMenu>
        <SubMenu title="PSSF Documents" icon={<FaFile />}>
            <MenuItem>
            <Link to="/provider/viewdocuments" rel="noopener noreferrer">
                View Documents
            </Link>
            </MenuItem>
            <MenuItem>
            <Link to="/provider/uploaddocuments" rel="noopener noreferrer">
                Upload Documents
            </Link>
            </MenuItem>
        </SubMenu> */}
            {/* <SubMenu title="Reports" icon={<FaFileAlt />}>
            <MenuItem>
            <Link
                to="/provider/programmaticreports"
                rel="noopener noreferrer"
            >
                Programmatic Reports
            </Link>
            </MenuItem>
            <MenuItem>
            <Link to="/provider/summaryreports" rel="noopener noreferrer">
                Summary Reports
            </Link>
            </MenuItem>
        </SubMenu>
        <MenuItem icon={<FaConciergeBell />}>
            <Link to="/provider/services" rel="noopener noreferrer">
            Services
            </Link>
        </MenuItem>
        <MenuItem icon={<FaGraduationCap />}>
            <Link to="/provider/staffqualifications" rel="noopener noreferrer">
            Staff Qualifications
            </Link>
        </MenuItem>
        <MenuItem icon={<FaCalendarDay />}>
            <Link to="/provider/events" rel="noopener noreferrer">
            Training &amp; Events
            </Link>
        </MenuItem>
        <MenuItem icon={<FaUser />}>
            <Link to="/provider/users" rel="noopener noreferrer">
            Users
            </Link>
        </MenuItem> */}
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <FaUser />
            <Link
              to="/account/logout"
              rel="noopener noreferrer"
              className="text-white"
            >
              Logout
            </Link>
          </div>
        </SidebarFooter>
      </ProSidebar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" controlId="programId">
              <Form.Select
                aria-label="Default select example"
                onChange={(Id) => {
                  sessionStorage.setItem("ProgramId", Id.target.value);

                  let kungFuJoe = findArrayElementByEdit(
                    data,
                    parseInt(Id.target.value)
                  );

                  sessionStorage.setItem(
                    "ProgramByFiscalYearId",
                    kungFuJoe.programByFiscalYearId
                  );
                }}
              >
                <option>Choose a program</option>
                {data.map((row) => (
                  <option value={row.programId} key={row.programId}>
                    {row.name} - {row.programId}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProviderSidebar;
